<template>
  <div>
    <table-pagination :pagination="pagination" :filter="filter" @load="loadData"></table-pagination>
    <b-table
      :data="rows"
      bordered
      narrowed
      :loading="loadingTable"
    >
      <template #empty>
        <p class="m-5 p-5 has-text-centered has-text-danger" v-if="!loadingTable">
          No hay registros para mostrar
        </p>
        <div v-else>
          <b-skeleton animated></b-skeleton>
          <b-skeleton animated></b-skeleton>
          <b-skeleton animated></b-skeleton>
          <b-skeleton animated></b-skeleton>
          <b-skeleton animated></b-skeleton>
        </div>
      </template>
      <b-table-column 
        label="N°"
        header-class="has-background-grey-dark has-text-white-bis" v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column 
        label="Nombre completo"
        header-class="has-background-grey-dark has-text-white-bis" v-slot="props">
        <b-icon 
          icon="user" 
          :type="props.row.persona.es_root ? 'is-primary' : (props.row.persona.es_admin ? 'is-success' : '')"></b-icon> 
        {{ props.row.persona.nombre_completo }}
      </b-table-column>
      <b-table-column 
        label="E-mail"
        header-class="has-background-grey-dark has-text-white-bis" v-slot="props">
        {{ props.row.email }}
      </b-table-column>
      <b-table-column 
        label="Usuario"
        header-class="has-background-grey-dark has-text-white-bis" v-slot="props">
        {{ props.row.username }}
      </b-table-column>
      <b-table-column 
        label="Estado"
        header-class="has-background-grey-dark has-text-white-bis" v-slot="{row}" cell-class="has-text-centered"  >
        <a href="#" @click.prevent="handleChangeStatus(row)">
          <b-icon :icon="row.persona.estado === '1' ? 'check-circle' : 'times'" :type="row.persona.estado === `1` ? `is-success` : `is-danger`" >
            {{ row.persona.estado }}
          </b-icon>
        </a>
      </b-table-column>
      <b-table-column 
        label="Fecha creado"
        header-class="has-background-grey-dark has-text-white-bis" v-slot="props">
        {{ props.row.persona.created_at }}
      </b-table-column>
      <b-table-column 
        label="Fecha actualizado"
        header-class="has-background-grey-dark has-text-white-bis" v-slot="props">
        {{ props.row.persona.updated_at }}
      </b-table-column>
      <b-table-column
        width="40"
        header-class="has-background-grey-dark has-text-white-bis"
        v-slot="{row}"
      >
        <b-dropdown position="is-bottom-left">
          <template #trigger>
            <b-button
              icon-left="ellipsis-v"
              type="is-primary is-light"
              size="is-small"
            />
          </template>
          <b-dropdown-item aria-role="listitem" @click="roles(row)"
            ><b-icon icon="users"></b-icon> Permisos</b-dropdown-item
          >
          <b-dropdown-item aria-role="listitem" @click="edit(row)"
            ><b-icon icon="edit"></b-icon> Editar</b-dropdown-item
          >
          <b-dropdown-item aria-role="listitem" @click="destroy(row)"
            ><b-icon icon="trash"></b-icon> Eliminar</b-dropdown-item
          >
        </b-dropdown>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  props:{
    loadingTable:{
      type: Boolean,
      default: () => false,
    }
  },
  computed:{
    name_store(){
      return `users`
    },
    getters(){
      return key => this.$store.getters[`${this.name_store}/${key}`]
    },
    rows(){
      return this.getters(`rows`)
    },
    pagination(){
      return this.getters(`pagination`)
    },
    filter(){
      return this.getters(`filter`)
    }
  },
  methods:{
    async loadData(url, params){
      this.$emit("onSubmit", url, params)
    },
    roles(row){
      this.$router.push({name: 'users-roles', params: {id: row.id}})
    },
    edit(row){
      this.$router.push({name: `users-nuevo`, query: {id: row.id}})
    },
    async handleChangeStatus(row){
      await this.$store.dispatch(`${this.name_store}/save`, {id: row.id, estado: row.persona.estado === `1` ? `0` : `1`})
      this.loadData()
    },
    async destroy(row){
      if (confirm(`Realmente desea eliminar el usuario "${row.persona.nombre_completo}"?`)) {
        const user = await this.$store.dispatch(`${this.name_store}/destroy`, row.id)
        if(user){
          this.$store.dispatch(
            "app/setToastSuccess",
            `Se acaba de eliminar el usuario: "${row.persona.nombre_completo}"`
          );
          this.loadData()
        }
      }
    }
  }
};
</script>