<template>
  <div>
    <title-bar :items="[{ title }]" >
      <template #buttonsleft>
        <button-header 
          tag="router-link"
          :to="{ name: `users-nuevo` }"
          icon-left="plus">
          Nuevo
        </button-header>
        <b-dropdown aria-role="list" class="mr-2 is-hidden">
          <template #trigger>
            <button-header
              icon-left="ellipsis-v"
            >
              Acciones
            </button-header>
          </template>
          <b-dropdown-item aria-role="listitem"><b-icon icon="file-upload"></b-icon> Importar desde excel</b-dropdown-item>
          <b-dropdown-item aria-role="listitem"><b-icon icon="file-excel"></b-icon> Exportar a excel</b-dropdown-item>
          <b-dropdown-item aria-role="listitem"><b-icon icon="file-pdf"></b-icon> Imprimir en PDF</b-dropdown-item>
        </b-dropdown>
        <button-header
          icon-left="filter"
          @click="isOpen = !isOpen"
          :focused="isOpen"
          aria-controls="contentIdForA11y2"
        >
          Filtrar
        </button-header>        
      </template>
    </title-bar>
    <TableComponent />
  </div>
</template>

<script>
import TableComponent from '../components/index/TableComponent.vue'
export default {
  components: {
    TableComponent,
  },
  computed:{
    title(){
      return this.$route.meta.title ?? "";
    },
    name_store(){
      return 'users'
    },
    getters(){
      return key => this.$store.getters[`${this.name_store}/${key}`]
    },
    isOpen:{
      get(){
        return this.getters(`isOpenFilter`)
      },
      set(e){
        this.$store.commit(`${this.name_store}/SET_IS_OPEN_FILTER`, e)
      }
    },
  }
}
</script>