<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns">
      <div class="column is-4">
        <input-base
          rules=""
          label="Nombre completo"
          placeholder="Nombres y Apellidos"
          v-model="form.nombre_completo"
          size="is-small"
        />
      </div>
      <div class="column is-4">
        <input-base
          label="Usuario"
          placeholder="Usuario"
          v-model="form.username"
          maxlength="200"
          size="is-small"
        />
      </div>
      <div class="column is-4">
        <input-base
          label="E-mail"
          placeholder="E-mail"
          v-model="form.email"
          maxlength="200"
          size="is-small"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <radio-base
          rules=""
          label="Estado"
          :options="[{id: '',value: 'Todos'},{id: '1',value: 'Activado'}, {id: '0',value: 'Desactivado'}]"
          v-model="form.estado"
        />
      </div>
      <div class="column is-4" v-if="isRoot">
        <radio-base
          rules=""
          label="Tipo usuario"
          :options="[{id: '',value: 'Todos'},{id: '1',value: 'Root'}, {id: '2',value: 'Administrador'},{id: '3',value: 'Normal'}]"
          v-model="form.tipo_user"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-flex is-align-items-center">
        <button-search />
        <button-clear
          v-model="form" 
          @clear="() => $refs.observer.reset()" />
      </div>
    </div>
  </form-observer>
</template>
<script>
import {getQueryUrl} from "@/utils/helpers"
import {mapGetters} from 'vuex'
export default {
  data() {
    return {
      form: {},
    };
  },
  computed:{
    ...mapGetters({
      isRoot: 'auth/isRoot',
    }),
    name_store(){
      return `users`
    },
  },
  created() {
    const e = getQueryUrl()
    if(e) {
      this.$set(this, 'form', e)
    }
    this.onSubmit()
  },
  methods: {
    async onSubmit(){
      if(this.$refs.observer){
        const isValid = await this.$refs.observer.validate();
        if (!isValid) return;
      }
      return this.$emit('onSubmit', null, this.form)
    },
  }
};
</script>