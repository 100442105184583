<template>
  <div class="is-clearfix">
    <b-collapse
      :aria-id="ariaId"
      animation="slide"
      v-model="isOpen"
    >
      <div class="box">
        <h2 class="is-size-5 has-text-link mb-2">Filtrar registros</h2>
        <FilterForm
          @onSubmit="onLoadItems" />
      </div>
    </b-collapse>
    <div class="columns mt-3">
      <div class="column">
        <TableList
          :loadingTable="loading_table"
          @onSubmit="onLoadItems" />
      </div>
    </div>
  </div>
</template>
<script>
import FilterForm from './FilterForm.vue'
import TableList from './TableList.vue'
export default {
  props:{
    ariaId: {
      type: String,
      default: () => "contentIdForA11y2"
    }
  },
  data(){
    return {
      loading_table: true,
    }
  },
  components: {
    FilterForm,
    TableList,
  },
  computed:{
    name_store(){
      return 'users'
    },
    getters(){
      return key => this.$store.getters[`${this.name_store}/${key}`]
    },
    isOpen(){
      return this.getters(`isOpenFilter`)
    }
  },
  methods: {
    async onLoadItems(url, params){
      this.loading_table = true
      await this.$store.dispatch(`${this.name_store}/paginate`,{url, params})
      this.loading_table = false
    }
  }
}
</script>